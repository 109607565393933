var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "component-paginatedDataTableParts",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "headers": _vm.headers,
      "items": _vm.partsData ? _vm.partsData.parts : [],
      "items-per-page": _vm.pageSize,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.localSortDesc,
      "custom-sort": _vm.noSortFn,
      "disable-sort": _vm.loading,
      "hide-default-footer": "",
      "must-sort": ""
    },
    on: {
      "update:sort-by": _vm.updateSortBy,
      "update:sort-desc": _vm.updateSortOrder
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var _item$homologation, _item$homologation2, _item$homologation3, _item$homologation4, _item$homologation5, _item$homologation6, _item$homologation7, _item$homologation8, _item$homologation9, _item$brand_id, _item$owner, _item$owner2, _item$dna, _item$homologation10, _item$dna2, _item$homologation11, _item$dna3, _item$homologation12, _item$dna4, _item$dna5, _item$homologation13, _item$dna6, _item$homologation14, _item$dna7, _item$homologation15, _item$dna8, _item$homologation16, _item$homologation16$;
        var item = _ref.item;
        return [_c('tr', {
          ref: `row_${item._id}`,
          staticClass: "row-table",
          on: {
            "click": function ($event) {
              return _vm.goToPageItem(item);
            },
            "mouseover": function ($event) {
              return _vm.rowMouseover(item);
            },
            "mouseleave": function ($event) {
              return _vm.rowMouseleave(item);
            },
            "mouseenter": function ($event) {
              return _vm.rowMouseenter(item);
            }
          }
        }, [_c('td', {
          staticClass: "text-center"
        }, [item.status === 'NEW' ? _c('v-badge', {
          staticClass: "badgeNew px-2",
          attrs: {
            "color": "primary",
            "title": _vm.$t('NEW'),
            "content": "New",
            "left": "",
            "inline": "",
            "small": "",
            "shaped": "",
            "label": ""
          }
        }) : item.status === 'HOMOLOGATION' ? _c('div', [((_item$homologation = item.homologation) === null || _item$homologation === void 0 ? void 0 : _item$homologation.status) !== 'REJECTED' ? _c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "orange",
            "title": _vm.$t('HOMOLOGATION'),
            "small": ""
          }
        }, [_vm._v(" fas fa-lock ")]) : _c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "orange",
            "title": _vm.$t('HOMOLOGATION'),
            "small": ""
          }
        }, [_vm._v(" fas fa-lock-open ")]), ((_item$homologation2 = item.homologation) === null || _item$homologation2 === void 0 ? void 0 : _item$homologation2.status) === 'NEW' ? _c('v-chip', {
          staticClass: "primary px-2",
          attrs: {
            "small": "",
            "dense": ""
          }
        }, [_c('span', {
          staticClass: "caption"
        }, [_vm._v("NEW")])]) : ((_item$homologation3 = item.homologation) === null || _item$homologation3 === void 0 ? void 0 : _item$homologation3.status) === 'CSG_COMPUTATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fab fa-python ")]) : ((_item$homologation4 = item.homologation) === null || _item$homologation4 === void 0 ? void 0 : _item$homologation4.status) === 'SUPPLIER_QUOTATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-industry ")]) : ((_item$homologation5 = item.homologation) === null || _item$homologation5 === void 0 ? void 0 : _item$homologation5.status) === 'REJECTED' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v(" fa fa-times ")]) : ((_item$homologation6 = item.homologation) === null || _item$homologation6 === void 0 ? void 0 : _item$homologation6.status) === 'VALIDATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fas fa-clock ")]) : ((_item$homologation7 = item.homologation) === null || _item$homologation7 === void 0 ? void 0 : _item$homologation7.status) === 'READY' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fa fa-arrow-alt-circle-right ")]) : ((_item$homologation8 = item.homologation) === null || _item$homologation8 === void 0 ? void 0 : _item$homologation8.status) === 'ORDERED' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fa fa-truck ")]) : ((_item$homologation9 = item.homologation) === null || _item$homologation9 === void 0 ? void 0 : _item$homologation9.status) === 'TESTING' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fa fa-vial ")]) : _vm._e()], 1) : item.status === 'HOMOLOGATED' ? _c('div', [_c('v-icon', {
          attrs: {
            "color": "success",
            "title": _vm.$t('HOMOLOGATED')
          }
        }, [_vm._v(" fas fa-check-circle ")])], 1) : _vm._e()], 1), _c('v-tooltip', {
          ref: `tooltip_image_${item._id}`,
          attrs: {
            "top": "",
            "content-class": "component-paginatedDataTableParts tooltip-image",
            "open-delay": _vm.partsImageDataURL[item._id] ? 35 : 150,
            "color": "transparent",
            "transition": "fade-transition",
            "disabled": !_vm.enableImagePreview
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('td', _vm._g({
                ref: `col_name_${item._id}`
              }, on), [_vm._v(" " + _vm._s(item.name) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.imageTooltipValues[item._id],
            callback: function ($$v) {
              _vm.$set(_vm.imageTooltipValues, item._id, $$v);
            },
            expression: "imageTooltipValues[item._id]"
          }
        }, [_c('v-card', {
          staticClass: "d-flex justify-center align-center",
          attrs: {
            "width": "256px",
            "height": "256px",
            "elevation": "12",
            "shaped": ""
          }
        }, [_c('v-card-text', {
          staticClass: "text-center"
        }, [!item.image ? _c('div', {
          staticClass: "px-4 pt-4 text-center"
        }, [_c('v-skeleton-loader', {
          attrs: {
            "type": "image",
            "height": "170px",
            "boilerplate": "",
            "elevation": "4"
          }
        }), _c('p', {
          staticClass: "text-subtitle-1 mt-4"
        }, [_vm._v(" " + _vm._s(_vm.$t('NoAvailableImage')) + " ")])], 1) : _vm.partsImageDataURL[item._id] ? _c('v-img', {
          attrs: {
            "src": _vm.partsImageDataURL[item._id]
          }
        }) : _c('div', [_c('SpinnerBeelse', {
          attrs: {
            "color": "orange",
            "display-logo": false,
            "size": 92
          }
        }), _c('p', {
          staticClass: "mt-8"
        }, [_vm._v(" " + _vm._s(_vm.$t('LoadingImage')) + " ")])], 1)], 1)], 1)], 1), _c('td', [_c('span', [_vm._v(" " + _vm._s((_item$brand_id = item.brand_id) === null || _item$brand_id === void 0 ? void 0 : _item$brand_id.name) + " ")])]), _c('td', [_c('span', [_vm._v(" " + _vm._s((_item$owner = item.owner) === null || _item$owner === void 0 ? void 0 : _item$owner.firstname) + " " + _vm._s((_item$owner2 = item.owner) === null || _item$owner2 === void 0 ? void 0 : _item$owner2.lastname) + " ")])]), _c('td', [(_item$dna = item.dna) !== null && _item$dna !== void 0 && _item$dna.technology ? _c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [(_item$homologation10 = item.homologation) !== null && _item$homologation10 !== void 0 && _item$homologation10.technology && item.homologation.technology !== item.dna.technology ? _c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fas fa-dna ")]) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm.$t(item.dna.technology)) + " ")])], 1) : _vm._e(), (_item$dna2 = item.dna) !== null && _item$dna2 !== void 0 && _item$dna2.technology && (_item$homologation11 = item.homologation) !== null && _item$homologation11 !== void 0 && _item$homologation11.technology && item.dna.technology !== item.homologation.technology || !((_item$dna3 = item.dna) !== null && _item$dna3 !== void 0 && _item$dna3.technology) && (_item$homologation12 = item.homologation) !== null && _item$homologation12 !== void 0 && _item$homologation12.technology ? _c('v-chip', {
          class: {
            'ml-2': (_item$dna4 = item.dna) === null || _item$dna4 === void 0 ? void 0 : _item$dna4.technology
          },
          attrs: {
            "small": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-certificate ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t(item.homologation.technology)) + " ")])], 1) : _vm._e()], 1), _c('td', [(_item$dna5 = item.dna) !== null && _item$dna5 !== void 0 && _item$dna5.material ? _c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [(_item$homologation13 = item.homologation) !== null && _item$homologation13 !== void 0 && _item$homologation13.material && item.homologation.material !== item.dna.material ? _c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fas fa-dna ")]) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm.$t(item.dna.material)) + " ")])], 1) : _vm._e(), (_item$dna6 = item.dna) !== null && _item$dna6 !== void 0 && _item$dna6.material && (_item$homologation14 = item.homologation) !== null && _item$homologation14 !== void 0 && _item$homologation14.material && item.dna.material !== item.homologation.material || !((_item$dna7 = item.dna) !== null && _item$dna7 !== void 0 && _item$dna7.material) && (_item$homologation15 = item.homologation) !== null && _item$homologation15 !== void 0 && _item$homologation15.material ? _c('v-chip', {
          class: {
            'ml-2': (_item$dna8 = item.dna) === null || _item$dna8 === void 0 ? void 0 : _item$dna8.material
          },
          attrs: {
            "small": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-certificate ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t(item.homologation.material)) + " ")])], 1) : _vm._e()], 1), _c('td', [_vm._v(" " + _vm._s((_item$homologation16 = item.homologation) === null || _item$homologation16 === void 0 ? void 0 : (_item$homologation16$ = _item$homologation16.supplier) === null || _item$homologation16$ === void 0 ? void 0 : _item$homologation16$.name) + " ")]), _c('td', [_c('span', {
          staticClass: "d-none d-lg-block"
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")]), _c('span', {
          staticClass: "d-block d-lg-none"
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "numeric", _vm.$userLocale)) + " ")])]), _c('td', [_c('span', {
          staticClass: "d-none d-lg-block"
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.last_modified), "long", _vm.$userLocale)) + " ")]), _c('span', {
          staticClass: "d-block d-lg-none"
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.last_modified), "numeric", _vm.$userLocale)) + " ")])]), _c('td', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-btn', {
          attrs: {
            "color": "info"
          }
        }, [_c('v-icon', [_vm._v(" fas fa-eye ")])], 1)], 1)], 1)];
      }
    }, {
      key: "top",
      fn: function () {
        var _vm$partsData$count, _vm$partsData;
        return [_c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          staticClass: "py-6",
          attrs: {
            "cols": "auto",
            "align-self": "center"
          }
        }, [_c('v-select', {
          staticClass: "selectPageSize",
          attrs: {
            "disabled": _vm.loading,
            "hide-details": "",
            "dense": "",
            "items": _vm.itemsPageSize
          },
          on: {
            "input": _vm.pageSizeChanged
          },
          scopedSlots: _vm._u([{
            key: "prepend",
            fn: function () {
              return [_c('span', {
                staticClass: "text-caption mr-2"
              }, [_vm._v(" " + _vm._s(_vm.$t('ItemsPerPage')) + " ")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.localPageSize,
            callback: function ($$v) {
              _vm.localPageSize = $$v;
            },
            expression: "localPageSize"
          }
        })], 1), _c('v-col', {
          staticClass: "py-6",
          attrs: {
            "cols": "auto",
            "align-self": "center"
          }
        }, [_c('span', {
          staticClass: "mr-4"
        }, [_vm._v(" " + _vm._s(_vm.currentMinItemIndex) + "-" + _vm._s(_vm.currentMaxItemIndex) + " " + _vm._s(_vm.$t('Of').toLowerCase()) + " " + _vm._s((_vm$partsData$count = (_vm$partsData = _vm.partsData) === null || _vm$partsData === void 0 ? void 0 : _vm$partsData.count) !== null && _vm$partsData$count !== void 0 ? _vm$partsData$count : '0') + " ")]), _c('v-btn', {
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === 0,
            "icon": ""
          },
          on: {
            "click": _vm.loadFirstPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-double-left ")])], 1), _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === 0,
            "icon": ""
          },
          on: {
            "click": _vm.loadPreviousPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-left ")])], 1), _c('span', {
          staticClass: "mr-2"
        }, [_vm.partsData ? _c('span', [_vm._v(" " + _vm._s(_vm.pageNumber + 1) + " / " + _vm._s(Math.ceil(_vm.partsData.count / _vm.pageSize)) + " ")]) : _c('span', [_vm._v(" - / - ")])]), _c('v-btn', {
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === Math.ceil(_vm.partsData.count / _vm.pageSize) - 1,
            "icon": ""
          },
          on: {
            "click": _vm.loadNextPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-right ")])], 1), _c('v-btn', {
          staticClass: "mr-8",
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === Math.ceil(_vm.partsData.count / _vm.pageSize) - 1,
            "icon": ""
          },
          on: {
            "click": _vm.loadLastPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-double-right ")])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        var _vm$partsData$count2, _vm$partsData2;
        return [_c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto",
            "align-self": "center"
          }
        }, [_c('v-select', {
          staticClass: "selectPageSize",
          attrs: {
            "disabled": _vm.loading,
            "hide-details": "",
            "dense": "",
            "items": _vm.itemsPageSize
          },
          on: {
            "input": _vm.pageSizeChanged
          },
          scopedSlots: _vm._u([{
            key: "prepend",
            fn: function () {
              return [_c('span', {
                staticClass: "text-caption mr-2"
              }, [_vm._v(" " + _vm._s(_vm.$t('ItemsPerPage')) + " ")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.localPageSize,
            callback: function ($$v) {
              _vm.localPageSize = $$v;
            },
            expression: "localPageSize"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto",
            "align-self": "center"
          }
        }, [_c('span', {
          staticClass: "mr-4"
        }, [_vm._v(" " + _vm._s(_vm.currentMinItemIndex) + "-" + _vm._s(_vm.currentMaxItemIndex) + " " + _vm._s(_vm.$t('Of').toLowerCase()) + " " + _vm._s((_vm$partsData$count2 = (_vm$partsData2 = _vm.partsData) === null || _vm$partsData2 === void 0 ? void 0 : _vm$partsData2.count) !== null && _vm$partsData$count2 !== void 0 ? _vm$partsData$count2 : '0') + " ")]), _c('v-btn', {
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === 0,
            "icon": ""
          },
          on: {
            "click": _vm.loadFirstPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-double-left ")])], 1), _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === 0,
            "icon": ""
          },
          on: {
            "click": _vm.loadPreviousPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-left ")])], 1), _c('span', {
          staticClass: "mr-2"
        }, [_vm.partsData ? _c('span', [_vm._v(" " + _vm._s(_vm.pageNumber + 1) + " / " + _vm._s(Math.ceil(_vm.partsData.count / _vm.pageSize)) + " ")]) : _c('span', [_vm._v(" - / - ")])]), _c('v-btn', {
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === Math.ceil(_vm.partsData.count / _vm.pageSize) - 1,
            "icon": ""
          },
          on: {
            "click": _vm.loadNextPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-right ")])], 1), _c('v-btn', {
          staticClass: "mr-8",
          attrs: {
            "disabled": _vm.loading || !_vm.partsData || _vm.pageNumber === Math.ceil(_vm.partsData.count / _vm.pageSize) - 1,
            "icon": ""
          },
          on: {
            "click": _vm.loadLastPage
          }
        }, [_c('v-icon', [_vm._v(" fas fa-angle-double-right ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }