<template lang="html" src="./pagePart.template.vue"></template>

<style lang="scss" src="./pagePart.scss"></style>

<script>
import {
  ApiErrorParser,
  BtnMenu,
  DnaFormV3,
  DownloadPartFile,
  DownloadBrandPartOriginalFile,
  DownloadPartViewer3dFile,
  EventBus,
  PartTags,
  PriceEstimatesV2,
  SpinnerBeelse,
  OriginalFileConverterDialog,
  Steppers,
  UploadManager,
  Viewer3d,
} from '@cloudmanufacturingtechnologies/portal-components';

import PartDetailedSuppliersPrices from '../../components/partDetailedSuppliersPrices/PartDetailedSuppliersPrices.vue';
import PartAttachmentsManager from '../../components/partAttachmentsManager/PartAttachmentsManager';

const i18nData = require('./pagePart.i18n');

export default {
  name: 'PagePart',
  components: {
    BtnMenu,
    DnaFormV3,
    DownloadPartViewer3dFile,
    PartAttachmentsManager,
    PartTags,
    PartDetailedSuppliersPrices,
    PriceEstimatesV2,
    SpinnerBeelse,
    OriginalFileConverterDialog,
    Steppers,
    UploadManager,
    Viewer3d,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brand: null,
      part: null,
      arrayBuffer: null,
      partDownloadName: '',
      partFileDataURL: {},
      brandPartsTags: [],
      downloadChoices: [],
      requiredPrices: false,
      edit: false,
      keyComponent: 0,
      link: null,
      locked: null,
      on: false,
      partFile: '',
      partsEditData: [],
      uploadStatus: 0,
      uploadErrorMessage: null,
      priceEstimates: null,
      printers: null,
      printersBrand: [],
      printersModel: [],
      needModel: false,
      manufacturingInfoEdit: false,
      homologationInfoEdit: false,
      dnaFormEditor: false,
      homologation: null,
      homologationPrices: [],
      partPricesEdit: false,
      step: null,
      viewer3dFile: null,
      wizardItems: [],
      loadingFile: false,
      viewerFullscreen: false,
      dialogAnalysis: false,
      editingPrice: false,
      modifiedHomologation: false,
      pricesRangeRules: [],
      brandFeatures: null,
      tagsModified: false,
      brandPartTagsErrorMessage: null,
      technologiesAndMaterials: null,
      checkboxMainAnalysis: true,
      checkboxSlices: true,
      availablePrinters: null,
      suppliers: null,
      suppliersLoading: false,
      suppliersPricingModal: false,
      partModifications: null,
      dialogAttachments: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.link = from.path;
    });
  },
  created() {
    this.getAllTechnologiesAndMaterials();
    this.getBrandPart();
    this.getStepProgress();
    this.wizardItems = [
      this.$t('INFORMATIONS'),
      this.$t('HOMOLOGATION'),
      this.$t('FIRST ORDER'),
      this.$t('HOMOLOGATED'),
    ];
  },
  methods: {
    updateDownloadChoices() {
      this.downloadChoices = [
        {
          text: '.STL',
          value: 'stl'
        },
        {
          text: '.3MF',
          value: '3mf'
        }
      ];
      if(this.part && this.part.originalFile) {
        this.downloadChoices.splice(0,0,{
          value: this.part.originalFile.extension,
          text: `.${this.part.originalFile.extension.toUpperCase()}`
        });
      }
    },
    /**
     * GET BRAND PART
     */
    getBrandPart() {
      this.$apiInstance
        .getAdminBrandPart(this.$route.params.partUUID)
        .then(
          (partData) => {
            this.part = partData;

            if(this.part.homologation) {
              if(!this.part.homologation.manufacturingInformations) {
                this.part.homologation.manufacturingInformations = {};
              }
              if (!this.part.homologation.manufacturingInformations.printer) {
                this.part.homologation.manufacturingInformations.printer = {};
              }
            }

            this.updateDownloadChoices();
            this.getStepProgress();
            this.getBrand();
            this.getSuppliers();
            this.getBrandPartsTags();
            if(this.part.status === 'NEW' && this.part.nestingSize) {
              this.getBrandPartEstimatedPrices();
            }
            if (!this.viewer3dFile && this.part.file) {
              this.getPartViewer3dFile();
            }

            this.homologationPrices = [];
            if (this.part.homologation && this.part.homologation.prices) {
              for (let i = 0; i < this.part.homologation.prices.length; i++) {
                this.homologationPrices.push(Object.assign({}, this.part.homologation.prices[i]));
              }
            }
            if (this.part.homologation && this.part.homologation.supplier) {
              this.getAllPrinters();
            }

            this.updatePriceRangeRules();

            if (
              (this.$route.params.brandUUID === this.part.brand_id && this.$route.params.partUUID === this.part._id)
              && (this.part.originalFile && (this.part.fileConversionStatus === 'IN_PROGRESS' || this.part.fileConversionStatus === 'IDLE'))
              || this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING'
              || this.part.slicingStatus === 'IDLE' || this.part.slicingStatus === 'IN_PROGRESS'
              // this.$router.currentRoute.name === 'Part'
            ) {
              const self = this;
              setTimeout(() => {
                self.getBrandPart();
              }, 10000);
            }
            this.editingPrice = false;
            this.forceRerender();
            this.tagsChange(false);
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    getBrandPartEstimatedPrices() {
      this.$apiInstance
        .getBrandPartEstimatedPricesV2(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          {source: 'both'}
        )
        .then(
          (priceEstimates) => {
            this.priceEstimates = priceEstimates;
          },
          (error) => {}
        );
    },
    /**
     * GET PART FILE
     */
    getPartFile(extension = null) {
      this.loadingFile = true;
      extension = extension || 'stl';

      this.partDownloadName = this.part.name + '.' + extension;

      if(!this.partFileDataURL[extension]) {

        let promise, req = null;

        if(['step', 'obj', 'iges'].includes(extension)) {
          [promise, req] = DownloadBrandPartOriginalFile.downloadBrandPartOriginalFile(
            this.$apiInstance,
            this.$route.params.brandUUID,
            this.$route.params.partUUID
          );
        } else {
          [promise, req] = DownloadPartFile.downloadPartFile(
            this.$apiInstance,
            this.$route.params.brandUUID,
            this.$route.params.partUUID,
            extension
          );
        }

        promise.then((response) => {
          this.arrayBuffer = response.buffer;
          const byteArray = new Uint8Array(this.arrayBuffer);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });
          this.partFileDataURL[extension] = URL.createObjectURL(blob);
          this.$refs.downloadFileButton.href = this.partFileDataURL[extension];
          this.loadingFile = false;
          setTimeout(() => {
            this.$refs.downloadFileButton.click();
          }, 250);
        });
        this.$downloadProgress.addDownload(req, this.partDownloadName, promise);

      } else {

        this.$refs.downloadFileButton.href = this.partFileDataURL[extension];
        setTimeout(() => {
          this.$refs.downloadFileButton.click();
        }, 250);
        setTimeout(() => {
          this.loadingFile = false;
        }, 1000);
      }
    },
    /**
     * GET BRAND
     */
    getBrand() {
      this.$apiInstance.getAdminBrand(this.$route.params.brandUUID).then(
        (brand) => {
          this.brand = brand;
        },
        (error) => {
          /**
           * ERROR GET BRAND
           */
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        }
      );
    },
    /**
     * GET PART VIEWER3D FILE
     */
    getPartViewer3dFile() {
      this.getPartModifications();
      this.viewer3dFile = null;
      const oReq = DownloadPartViewer3dFile.downloadPartViewer3dFile(
        this.$apiInstance,
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        new Date(this.part.viewer3dFile.created).getTime()
      );
      oReq.onloadend = () => {
        this.viewer3dFile = {
          extension: 'blsv',
          buffer: Buffer.from(oReq.response)
        };
      };
    },
    getPartModifications() {
      if(!this.part.modifications) {
        this.partModifications = [];
      } else {
        this.$apiInstance
          .getBrandPartModifications(
            this.$route.params.brandUUID,
            this.$route.params.partUUID
          )
          .then(
            (data) => {
              this.partModifications = data;
            },
            (error) => {
              //Modifications not specified
              this.partModifications = [];
            }
          );
      }
    },
    getAllTechnologiesAndMaterials() {
      this.$apiInstance
        .getAllTechnologiesAndMaterialsCompatibilities()
        .then((technologiesAndMaterials) => {
                this.technologiesAndMaterials = technologiesAndMaterials;
              },
              /**
               * ERROR GET ALL TECHNOLOGIES AND MATERIALS
               */
              (error) => {
                this.$notification.notify(
                  'DANGER',
                  this.$t(ApiErrorParser.parse(error))
                );
              });
    },
    /**
     * RESTART BRAND PART ANALYSIS
     */
    restartBrandPartAnalysis() {
      this.viewer3dFile = null;
      this.partViewer3dFile = '';
      this.part.analysisStatus = 'IDLE';
      this.dialogAnalysis = false;

      const partAnalysisBody = new this.$BcmModel.AnalyzePartBody(this.checkboxMainAnalysis, this.checkboxSlices);

      this.$apiInstance.prepareBrandPartAnalysis(
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        partAnalysisBody
      ).then(() => {
        this.getBrandPart();
      });
    },
    getStepProgress() {
      this.step = 0;
      if (!this.part) {
        return;
      }
      switch (this.part.status) {
      case 'NEW':
        if (this.part.dna && this.part.dna.completed && this.part.file) {
          this.step = 1;
        }
        break;
      case 'HOMOLOGATION':
        this.step = 2;
        if (this.part.homologation?.status === 'TESTING') {
          this.step = 4;
        }
        break;
      case 'HOMOLOGATED':
        this.step = 5;
        break;
      }
    },
    checkProgress(done) {
      this.uploadStatus = done;
    },
    updateViewer() {
      this.getPartViewer3dFile();
    },
    /**
     * MODIFY CRITICAL DIMENSIONS
     */
    modifyBrandPartCriticalDimensions(criticalDimensions) {
      const modifyBrandPartCriticalDimensionsBody = new this.$BcmModel.ModifyBrandPartCriticalDimensionsBody(
        criticalDimensions
      );
      this.$apiInstance
        .modifyBrandPartCriticalDimensions(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          modifyBrandPartCriticalDimensionsBody
        )
        .then(
          (data) => {
            this.$notification.notify('SUCCESS',this.$t('SuccessCriticalDimensions'));
            this.part.criticalDimensions = data.criticalDimensions;
            this.displayViewer = false;
            setTimeout(() => {
              this.displayViewer = true;
            }, 100);
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error), {timeout: 10000}));
          }
        );
    },
    /**
     * MODIFY MANUFACTURING INFORMATIONS
     */
    modifyManufacturingInformations() {
      const printerBrandFormatted = this.part.homologation.manufacturingInformations.printer.brand.replaceAll(' ', '_');
      const printerModelFormatted = this.part.homologation.manufacturingInformations.printer.model.replaceAll(' ', '_');

      const manufacturingInformations = this.$BcmModel.ManufacturingInformations.constructFromObject({
        printer: {
          brand: printerBrandFormatted,
          model: printerModelFormatted
        },
        material: this.part.homologation.manufacturingInformations.material,
        manufacturing_parameters: this.part.homologation.manufacturingInformations.manufacturing_parameters,
        finish: this.part.homologation.manufacturingInformations.finish
      });

      this.$apiInstance
        .modifyHomologationManufacturingInformations(
          this.$route.params.partUUID,
          manufacturingInformations
        )
        .then(
          () => {
            this.manufacturingInfoEdit = false;
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('manufacturingInformationsModifiedSuccessfully'));
            this.getBrandPart();
          },
          (error) => {
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * CANCEL MANUFACTURING INFORMATIONS EDIT
     */
    cancelManufacturingInfoEdit() {
      this.manufacturingInfoEdit = false;
      this.getBrandPart();
    },
    /**
     * CANCEL HOMOLOGATION INFORMATIONS EDIT
     */
    cancelHomologationInfoEdit() {
      this.homologationInfoEdit = false;
      this.getBrandPart();
    },
    /**
     * GET BRAND PARTS TAGS
     */
    getBrandPartsTags() {
      this.$apiInstance.getBrandPartsTags(this.$route.params.brandUUID).then(
        (brandPartsTagsData) => {
          this.brandPartsTags = brandPartsTagsData;
        },
        (error) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        }
      );
    },
    /**
     * MODIFY BRAND PART TAGS
     */
    modifyBrandPartTags() {
      const brandPartTags = new this.$BcmModel.ModifyBrandPartTagsBody(
        this.part.tags
      );
      this.$apiInstance
        .modifyBrandPartTags(
          this.$route.params.brandUUID,
          this.$route.params.partUUID,
          brandPartTags
        )
        .then(
          (brandPartTagsData) => {
            this.part.tags = brandPartTagsData.tags;
            this.getBrandPart();
            this.$notification.notify('SUCCESS',this.$t('TagSuccessfullyModified'));
          },
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    tagsChange(event) {
      EventBus.$emit('tagsChange', event);
    },
    removeBrandPartTag(item) {
      this.part.tags.splice(this.part.tags.indexOf(item), 1);
      this.part.tags = [...this.part.tags];
      this.tagsChange(true);
    },
    switchFullscreen(e) {
      this.viewerFullscreen = e;
    },
    /**
     * GET ALL PRINTERS
     */
    getAllPrinters() {
      this.$apiInstance
        .getSupplierPrinters(this.part.homologation.supplier._id)
        .then(
          (printersData) => {
            this.printers = printersData;
            this.getBrands();
            if(this.part.homologation 
              && this.part.homologation.manufacturingInformations.printer 
              && this.part.homologation.manufacturingInformations.printer.brand) {
              this.getModels(this.part.homologation.manufacturingInformations.printer.brand);
            }
          },
          (error) => {
            this.$notification.notify('ERROR',ApiErrorParser.parse(error));
          }
        );
    },
    getBrands() {
      this.printersBrand = [];
      const printerBrandSet = new Set();
      for (let i = 0; i < this.printers.length; i++) {
        if (
          this.printers[i].technology === this.part.homologation.technology &&
          !printerBrandSet.has(this.printers[i].brand)
        ) {
          this.printersBrand.push({
            value: this.printers[i].brand,
            text: this.printers[i].brand.replaceAll('_', ' ')
          });
          printerBrandSet.add(this.printers[i].brand);
        }
      }
    },
    getModels(brand) {
      this.printersModel = [];
      for (let i = 0; i < this.printers.length; i++) {
        if (
          this.printers[i].brand === brand &&
          this.printers[i].technology === this.part.homologation.technology
        ) {
          this.printersModel.push({
            value: this.printers[i].model,
            text: this.printers[i].model.replaceAll('_', ' ')
          });
        }
      }
      this.needModel = false;
    },
    modelIsSelected() {
      this.needModel = true;
    },
    formInput() {
      if(this.part.homologation
        && this.part.homologation.manufacturingInformations.printer 
        && this.part.homologation.manufacturingInformations.printer.brand 
        && this.part.homologation.manufacturingInformations.printer.model) {
        this.needModel = true;
      } else {
        this.needModel = false;
      }
    },
    roundToDecimals(number) {
      return Math.round(number * 100) / 100;
    },
    /**
     * CALCULATE PRICES
     */
    calculatePrices(priceObject, typeOf, index) {
      if (priceObject[typeOf] < 0) {
        priceObject[typeOf] = null;
        this.$notification.notify('DANGER',this.$t('You cannot enter negative number'));
      } else if (priceObject[typeOf] === 0 && typeOf === 'quantity') {
        priceObject[typeOf] = null;
        this.$notification.notify('DANGER',this.$t('You cannot enter a quantity of less than 1'));
      } else {
        switch (typeOf) {
        case 'quantity':
          if (!priceObject.quantityRequired) {
            this.part.homologation.prices.forEach((price2, index2) => {
              if (index !== index2) {
                if (priceObject.quantity === price2.quantity) {
                  priceObject.quantity = null;
                  this.$notification.notify('DANGER',this.$t('cantHaveTwoPriceForSameQantity'));
                  return;
                }
              }
            });
          }
          break;
        case 'purchasePrice':
          if (Number.isFinite(priceObject.purchasePrice)) {
            if (priceObject.purchasePrice === 0) {
              priceObject.margin = 0;
              priceObject.sellingPrice = 0;
              break;
            }

            if (
              priceObject.sellingPrice &&
              priceObject.purchasePrice > priceObject.sellingPrice
            ) {
              priceObject.sellingPrice = priceObject.purchasePrice;
            }

            if (Number.isFinite(priceObject.sellingPrice)) {
              priceObject.margin = this.roundToDecimals(
                ((priceObject.sellingPrice - priceObject.purchasePrice) /
                  priceObject.purchasePrice) *
                  100
              );
            } else {
              priceObject.sellingPrice = Math.ceil(
                (1 + this.brand.features.defaultMargin / 100) *
                  priceObject.purchasePrice
              );
              priceObject.margin =
                100 * (priceObject.sellingPrice / priceObject.purchasePrice) -
                100;
            }
          }
          break;
        case 'sellingPrice':
          if (Number.isFinite(priceObject.sellingPrice)) {
            if (priceObject.sellingPrice === 0) {
              priceObject.margin = 0;
              priceObject.purchasePrice = 0;
              break;
            }

            if (priceObject.sellingPrice < priceObject.purchasePrice) {
              priceObject.sellingPrice = priceObject.purchasePrice;
            }

            if (Number.isFinite(priceObject.purchasePrice)) {
              priceObject.margin = this.roundToDecimals(
                ((priceObject.sellingPrice - priceObject.purchasePrice) /
                  priceObject.purchasePrice) *
                  100
              );
            }
          }
          break;
        default:
          this.$notification.notify('DANGER',this.$t('Calculation is not possible'));
        }
      }
      this.testPricesAndCompleteMissing(this.homologationPrices);
      if (
        priceObject.quantity &&
        Number.isFinite(priceObject.purchasePrice) &&
        Number.isFinite(priceObject.sellingPrice) &&
        !Number.isNaN(Number.parseFloat(priceObject.margin))
      ) {
        this.modifiedHomologation = true;
      }
      this.forceRerender();
    },
    testPricesAndCompleteMissing(prices) {
      let testFor1 = false;
      let testForRequiredPrices1 = false;
      for (let i = 0; i < prices.length; i++) {
        if (prices[i].quantity === 1) {
          testFor1 = true;
          if (
            Number.isFinite(prices[i].purchasePrice) &&
            Number.isFinite(prices[i].sellingPrice)
          ) {
            testForRequiredPrices1 = true;
          }
          break;
        }
      }
      if (testFor1 === false) {
        prices.push({
          quantity: 1,
          purchasePrice: null,
          margin: null,
          sellingPrice: null,
          quantityRequired: true,
        });
      }
      this.requiredPrices = testForRequiredPrices1 === true;
    },
    /**
     *  MODIFY PART PRICES
     */
    modifyHomologationPrices() {
      if (this.$refs.pricesForm.validate()) {
        const formattedPricesToSend = [];
        for (let i = 0; i < this.homologationPrices.length; i++) {
          if (
            this.homologationPrices[i].quantity !== 0 &&
            Number.isFinite(this.homologationPrices[i].purchasePrice) &&
            Number.isFinite(this.homologationPrices[i].margin) &&
            Number.isFinite(this.homologationPrices[i].sellingPrice)
          ) {
            if (this.homologationPrices[i].quantityRequired)
              delete this.homologationPrices[i].quantityRequired;
            formattedPricesToSend.push(this.homologationPrices[i]);
          }
        }
        const newHomologationPrices = new this.$BcmModel.ModifyPartHomologationPricesBody(
          formattedPricesToSend
        );
        this.$apiInstance
          .modifyPartHomologationPrices(
            this.$route.params.partUUID,
            newHomologationPrices
          )
          .then(
            () => {
              this.modifiedHomologation = false;
              this.$notification.notify('SUCCESS',this.$t('pricesModifiedSuccess'));
              this.getBrandPart();
              this.requiredPrices = false;
            },
            /**
             * ERROR GET BRAND PART
             */
            (error) => {
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    startBrandOriginalFileConversion(parameters) {
      const originalFileConversionParametersBody = this.$BcmModel.OriginalFileConversionParametersBody.constructFromObject(parameters);

      this.$apiInstance.startBrandPartOriginalFileConversion(
        this.$route.params.brandUUID,
        this.$route.params.partUUID,
        originalFileConversionParametersBody
      ).then(response => {
        this.$notification.notify('SUCCESS',this.$t('OriginalFileConversionWillRestart'));
        this.getBrandPart();
      }).catch(error => {
        this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
      });
    },
    /**
     * PART PRICES ACTIONS
     * */
    cancelPartPricesEdit() {
      this.partPricesEdit = false;
      this.requiredPrices = false;
      this.pricesRangeRules = [];
      this.getBrandPart();
    },
    addPricesForm() {
      this.homologationPrices.push({
        quantity: null,
        purchasePrice: null,
        margin: null,
        sellingPrice: null,
      });
      this.editingPrice = true;
    },
    deletePrice(index) {
      this.homologationPrices.splice(index, 1);
      this.editingPrice = false;
    },
    forceRerender() {
      this.keyComponent += 1;
    },
    getFormattedDate(timestamp) {
      const dt = new Date(timestamp);
      return `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${dt
        .getFullYear()
        .toString()
        .padStart(4, '0')} ${dt
        .getHours()
        .toString()
        .padStart(2, '0')}:${dt
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`;
    },
    /**
     * VERIFY PRICE RANGE
     */
    verifyPrice() {
      this.updatePriceRangeRules();
      setTimeout(this.$refs.pricesForm.validate, 200);
    },

    updatePriceRangeRules() {
      const pricesRange = [];
      this.homologationPrices.forEach((current, index) => {
        const price = {
          quantityMin: null,
          quantityMax: null,
          min: current.purchasePrice,
          max: current.purchasePrice,
        };

        //On cherche la quantité la plus proche inférieur et supérieur
        this.homologationPrices.forEach((test, index2) => {
          if (
            index2 !== index &&
            current.quantity !== test.quantity &&
            current.quantity > 0 &&
            current.purchasePrice > 0 &&
            test.purchasePrice &&
            test.purchasePrice > 0
          ) {
            if (test.quantity < current.quantity) {
              //Plus petite quantité donc le prix doit etre suppérieur
              if (!price.quantityMin || test.quantity >= price.quantityMin) {
                price.quantityMin = test.quantity; //la quantite la plus basse
                price.max = test.purchasePrice; //le prix le plus haut
              }
            } else {
              //Plus grande quantité donc le prix doit etre inférieur
              if (!price.quantityMax || test.quantity <= price.quantityMax) {
                price.quantityMax = test.quantity; //la quantite la plus haute
                price.min = test.purchasePrice; //le prix le plus bas
              }
            }
          }
        });

        const rules = [];
        //push empty
        rules.push((value) => !!value || this.$t('priceRequired'));
        if (!price.quantityMin) {
          price.max = null;
          //quantity min
          rules.push(
            (value) =>
              value >= price.min ||
              this.$t('PriceRangeSupTo', {
                min: price.min,
              })
          );
        } else if (!price.quantityMax) {
          price.min = null;
          //quantity max
          rules.push(
            (value) =>
              value <= price.max ||
              this.$t('PriceRangeInfTo', {
                max: price.max,
              })
          );
          rules.push(
            (value) =>
              value > 0 ||
              this.$t('PriceRangeSupTo', {
                max: 0,
              })
          );
        } else {
          //Between
          rules.push(
            (value) =>
              (value >= price.min && value <= price.max) ||
              this.$t('PriceRangeBetween', {
                min: price.min,
                max: price.max,
              })
          );
        }
        //push rules
        pricesRange.push(rules);
      });
      this.pricesRangeRules = pricesRange;
    },
    getSuppliers() {
      /**
       * Get all suppliers for prices algorithm
       */
      this.suppliersLoading = true;
      this.$apiInstance.getAllSuppliers().then(data => {
        data = data.filter(supplier => {        
          return (
            (supplier.public && this.brand.features.accessToBCMNetwork) || 
            (!supplier.public && this.brand.features.privateSuppliers.some(ps => ps.supplier._id === supplier._id ))
          );
        });
        this.suppliers = data;
        this.getAvailablePrinters();
      });
    },
    getAvailablePrinters() {
      /**
       * Get all printers for prices algorithm
       */
      this.$apiInstance.getPrinters().then(data => {
        data = data.filter(printer => {
          return this.suppliers.map(s => {return s._id;}).includes(printer.owner);
        });
        this.availablePrinters = data;
        this.suppliersLoading = false;
      });
    },
    closeSuppliersPricing() {
      this.suppliersPricingModal = false;
    },
    openSuppliersPricing() {
      if(!this.suppliers) {
        this.getSuppliers();
      }
      this.suppliersPricingModal = true;
    }
  },
};
</script>
