var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    }
  }, _vm._l(Object.keys(_vm.attachmentsByOwner), function (ownerUUID) {
    var _vm$suppliersList$fin;
    return _c('v-expansion-panel', {
      key: ownerUUID
    }, [_c('v-expansion-panel-header', [_c('div', [_c('v-chip', {
      staticClass: "mr-2",
      attrs: {
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(_vm.attachmentsByOwner[ownerUUID].length) + " ")]), _vm.brand._id === ownerUUID ? [_c('v-icon', {
      staticClass: "mr-3",
      attrs: {
        "small": "",
        "color": "primary"
      }
    }, [_vm._v(" fas fa-warehouse ")]), _vm._v(_vm._s(_vm.brand.name) + " ")] : [_c('v-icon', {
      staticClass: "mr-3",
      attrs: {
        "small": "",
        "color": "primary"
      }
    }, [_vm._v(" fas fa-city ")]), _vm._v(_vm._s((_vm$suppliersList$fin = _vm.suppliersList.find(function (i) {
      return i._id === ownerUUID;
    })) === null || _vm$suppliersList$fin === void 0 ? void 0 : _vm$suppliersList$fin.name) + " ")]], 2)]), _c('v-expansion-panel-content', [_c('AttachmentsManager', {
      attrs: {
        "attachments": _vm.attachmentsByOwner[ownerUUID] || [],
        "owner": _vm.$route.params.supplierUUID,
        "upload-status-and-progress": _vm.uploadStatusAndProgress,
        "shared-with": _vm.brand._id !== ownerUUID ? 'warehouse' : 'supplier',
        "disable-add-attachment": _vm.brand._id !== ownerUUID
      },
      on: {
        "addAttachment": _vm.addAttachment,
        "updateAttachment": _vm.updateAttachment,
        "downloadAttachment": _vm.downloadAttachment,
        "deleteAttachment": _vm.deleteAttachment,
        "refresh": _vm.refresh
      }
    })], 1)], 1);
  }), 1), _c('UploadManager', {
    ref: "UploadManagerRef",
    on: {
      "success": _vm.uploadSuccess,
      "error": _vm.uploadError,
      "progress": _vm.uploadProgress,
      "xhr": _vm.uploadProgress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }