<template lang="html" src="./pageParts.template.vue"></template>

<style lang="scss" src="./pageParts.scss"></style>

<script>
const i18nData = require('./pageParts.i18n');

import { ApiErrorParser, DownloadBrandPartImage, AutocompleteFilters } from '@cloudmanufacturingtechnologies/portal-components';

import PaginatedDataTableParts from '../../components/paginatedDataTableParts/PaginatedDataTableParts';

export default {
  name: 'PageParts',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  components: {
    AutocompleteFilters,
    PaginatedDataTableParts
  },
  data() {
    return {
      partsData: null,
      pageSize: 15,
      pageNumber: 0,
      partsImageDataURL: {},
      sortBy: 'last_modified',
      sortOrder: -1,
      loading: false,
      filters: null,
      technologies: [],
      materials: []
    };
  },
  created() {
    this.initFilters();
    this.getAllTechnologiesAndMaterials();
    this.getAdminQueriedParts();
  },
  methods: {
    getAdminQueriedParts() {
      this.loading = true;
      const options = {
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        status: this.filters.find(f => {return f.type === 'status';})?.items.filter(i => {return i.selected;}).map(i => {return i.value;}) ?? [],
        homologationStatus: this.filters.find(f => {return f.type === 'homologationStatus';})?.items.filter(i => {return i.selected;}).map(i => {return i.value;}) ?? [],
        technology: this.filters.find(f => {return f.type === 'technology';})?.items.filter(i => {return i.selected;}).map(i => {return i.value;}) ?? [],
        material: this.filters.find(f => {return f.type === 'material';})?.items.filter(i => {return i.selected;}).map(i => {return i.value;}) ?? [],
        created: this.filters.find(f => {return f.type === 'created';})?.items ?? [],
        search: this.filters.find(f => {return f.type === 'name';})?.value ?? null,
        /**
         * For some reason, the API options parser transforms underscore with uppercase of the next letter (last_modified -> lastModified)
         */
        lastModified: this.filters.find(f => {return f.type === 'last_modified';})?.items ?? [],
      };
      this.$apiInstance.getAdminQueriedParts(
        this.pageSize,
        this.pageNumber,
        options
      )
        .then(data => {
          for(const part of data.parts) {
            if(!this.partsImageDataURL[part._id]) {
              this.$set(this.partsImageDataURL, part._id, null);
            }
          }
          this.partsData = data;
        }, (error) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAllTechnologiesAndMaterials() {
      this.$apiInstance.getAllTechnologiesAndMaterialsCompatibilities()
        .then(data => {
          for(const technologyObj of data) {
            this.technologies.push({
              value: technologyObj.technology,
              text: technologyObj.technology
            });
            for(const materialObj of technologyObj.materials) {
              if(!this.materials.find(m => {return m.value === materialObj.material;})) {
                this.materials.push({
                  value: materialObj.material,
                  text: materialObj.name[this.$userLocale] ?? materialObj.material
                });
              }
            }
          }
          this.technologies.sort((t1, t2) => {
            return t1.text >= t2.text ? 1 : -1;
          });
          this.materials.sort((m1, m2) => {
            return m1.text >= m2.text ? 1 : -1;
          });
          this.initFilters();
        });
    },
    getBrandPartImage(part) {
      if(part.image) {
        const [promise, req] = DownloadBrandPartImage.downloadBrandPartImage(
          this.$apiInstance,
          part.brand_id._id, 
          part._id,
          new Date(part.image.created).getTime()
        );

        promise.then(data => {
          const png = new File([new Uint8Array(data.buffer)], 'part.png', {
            type: 'image/png'
          });
          this.partsImageDataURL[part._id] = URL.createObjectURL(png);
        });
      }
    },
    initFilters() {
      this.filters = [
        {
          filterType: 'autocomplete',
          type: 'status',
          label: this.$t('Status'),
          items: [
            {
              text: this.$t('NEW'),
              value: 'NEW',
              selected: false,
            },
            {
              text: this.$t('HOMOLOGATION'),
              value: 'HOMOLOGATION',
              selected: false,
            },
            {
              text: this.$t('HOMOLOGATED'),
              value: 'HOMOLOGATED',
              selected: false,
            },
          ]
        },
        {
          filterType: 'autocomplete',
          type: 'homologationStatus',
          label: this.$t('HomologationStatus'),
          items: [
            {
              text: this.$t('NEW'),
              value: 'NEW',
              selected: false,
            },
            {
              text: this.$t('CSG_COMPUTATION'),
              value: 'CSG_COMPUTATION',
              selected: false,
            },
            {
              text: this.$t('SUPPLIER_QUOTATION'),
              value: 'SUPPLIER_QUOTATION',
              selected: false,
            },
            {
              text: this.$t('REJECTED'),
              value: 'REJECTED',
              selected: false,
            },
            {
              text: this.$t('VALIDATION'),
              value: 'VALIDATION',
              selected: false,
            },
            {
              text: this.$t('READY'),
              value: 'READY',
              selected: false,
            },
            {
              text: this.$t('ORDERED'),
              value: 'ORDERED',
              selected: false,
            },
            {
              text: this.$t('TESTING'),
              value: 'TESTING',
              selected: false,
            }
          ]
        },
        {
          filterType: 'autocomplete',
          type: 'technology',
          label: this.$t('Technology'),
          items: this.technologies.map(tObj => {
            return {
              value: tObj.value,
              text: tObj.text,
              selected: false,
            };
          })
        },
        {
          filterType: 'autocomplete',
          type: 'material',
          label: this.$t('Material'),
          items: this.materials.map(mObj => {
            return {
              value: mObj.value,
              text: mObj.text,
              selected: false,
            };
          })
        },
        {
          filterType: 'datepicker',
          type: 'created',
          text: this.$t('Created'),
          items: []
        },
        {
          filterType: 'datepicker',
          type: 'last_modified',
          text: this.$t('LastModified'),
          items: []
        },
        {
          filterType: 'textfield',
          type: 'name',
          label: this.$t('PartName'),
          value: '',
        },
      ];
    },
    filtersChanged(filters) {
      let addHomologationStatus = false;
      let removeHomologationStatuses = false;

      if(!this.filters.find(f => {return f.type === 'status';})?.items.find(i => {return i.value === 'HOMOLOGATION';}).selected && filters.find(f => {return f.type === 'homologationStatus';})?.items.find(i => {return i.selected;})) {
        addHomologationStatus = true;
      }

      if(this.filters.find(f => {return f.type === 'status';})?.items.find(i => {return i.value === 'HOMOLOGATION';}).selected && !filters.find(f => {return f.type === 'status';})?.items.find(i => {return i.value === 'HOMOLOGATION';}).selected) {
        removeHomologationStatuses = true;
      }

      this.filters = JSON.parse(JSON.stringify(filters));

      if(addHomologationStatus) {
        const statusHomologation = this.filters.find(f => {return f.type === 'status';})?.items.find(i => {return i.value === 'HOMOLOGATION';});
        statusHomologation.selected = true;
      }

      if(removeHomologationStatuses) {
        this.filters.find(f => {return f.type === 'homologationStatus';}).items.forEach(i => {
          i.selected = false;
        });
      }

      this.getAdminQueriedParts();
    },
    pageSizeChanged(size) {
      /**
       * We also need to update pageNumber such that the current first item is still displayed
       */
      this.pageNumber = Math.floor((this.pageSize * this.pageNumber) / size);
      this.pageSize = size;

      this.getAdminQueriedParts();
    },
    loadPreviousPage() {
      this.pageNumber = Math.max(0, this.pageNumber - 1);
      this.getAdminQueriedParts();
    },
    loadNextPage() {
      this.pageNumber = Math.min(Math.ceil(this.partsData.count / this.pageSize) - 1, this.pageNumber + 1);
      this.getAdminQueriedParts();
    },
    loadFirstPage() {
      this.pageNumber = 0;
      this.getAdminQueriedParts();
    },
    loadLastPage() {
      this.pageNumber = Math.ceil(this.partsData.count / this.pageSize) - 1;
      this.getAdminQueriedParts();
    },
    updateSort(by, order) {
      this.pageNumber = 0;
      this.sortBy = by;
      this.sortOrder = order;
      this.getAdminQueriedParts();
    },
    goToPageItem(item) {
      this.$router.push({
        name: 'Part',
        params: {
          brandUUID: item.brand_id._id,
          partUUID: item._id
        }
      });
    },
    selectHomologatedParts() {
      this.initFilters();
      this.filters.find(f => {
        return f.type === 'status';
      }).items.find(i => {
        return i.value === 'HOMOLOGATED';
      }).selected = true;
      this.getAdminQueriedParts();
    },
    selectPartsAddedInTheLastWeek() {
      this.initFilters();
      const d = new Date();
      const d1 = new Date(new Date(new Date(d.setHours(0,0,0,0))).setDate(d.getDate() - 6)).toISOString();
      const d2 = new Date();
      this.filters.find(f => {
        return f.type === 'created';
      }).items = [d1, d2];
      this.getAdminQueriedParts();
    },
    selectPartsAddedInTheLastMonth() {
      this.initFilters();
      const d = new Date();
      let d1 = new Date();
      d1.setDate(d1.getDate() + 1);
      d1.setMonth(d1.getMonth() - 1);
      d1 = d1.toISOString();
      const d2 = new Date();
      this.filters.find(f => {
        return f.type === 'created';
      }).items = [d1, d2];
      this.getAdminQueriedParts();
    },
    selectPartsAddedThisYear() {
      this.initFilters();
      const d1 = new Date(`${new Date().getFullYear()}-01-01`).toISOString();
      const d2 = new Date();
      this.filters.find(f => {
        return f.type === 'created';
      }).items = [d1, d2];
      this.getAdminQueriedParts();
    },
  }
};
</script>
