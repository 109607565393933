<template>
  <div>
    <v-expansion-panels multiple>
      <v-expansion-panel
        v-for="ownerUUID in Object.keys(attachmentsByOwner)"
        :key="ownerUUID"
      >
        <v-expansion-panel-header>
          <div>
            <v-chip class="mr-2" color="primary">
              {{ attachmentsByOwner[ownerUUID].length }}
            </v-chip>
            <template v-if="brand._id === ownerUUID">
              <v-icon
                small
                color="primary"
                class="mr-3"
              >
                fas fa-warehouse
              </v-icon>{{ brand.name }}
            </template>
            <template v-else>
              <v-icon
                small
                color="primary"
                class="mr-3"
              >
                fas fa-city
              </v-icon>{{ suppliersList.find(i => i._id === ownerUUID)?.name }}
            </template>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <AttachmentsManager
            :attachments="attachmentsByOwner[ownerUUID] || []"
            :owner="$route.params.supplierUUID"
            :upload-status-and-progress="uploadStatusAndProgress"
            :shared-with="brand._id !== ownerUUID ? 'warehouse' : 'supplier'"
            :disable-add-attachment="brand._id !== ownerUUID"
            @addAttachment="addAttachment"
            @updateAttachment="updateAttachment"
            @downloadAttachment="downloadAttachment"
            @deleteAttachment="deleteAttachment"
            @refresh="refresh"
          ></AttachmentsManager>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <UploadManager
      ref="UploadManagerRef"
      @success="uploadSuccess"
      @error="uploadError"
      @progress="uploadProgress"
      @xhr="uploadProgress"
    ></UploadManager>
  </div>
</template>

<script>
import { AttachmentsManager, UploadManager, ApiErrorParser, DownloadAdminPartAttachmentFile } from '@cloudmanufacturingtechnologies/portal-components';

export default {
  name: 'PartAttachmentsManager',
  components: { AttachmentsManager, UploadManager },
  props: {
    /** The part object */
    part: {
      type: Object,
      default: null
    },
    /** The brand object */
    brand: {
      type: Object,
      default: null
    },
    /** The suppliers object */
    suppliers: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      attachmentsByOwner: {},
      brandObject: {},
      suppliersList: [],
      uploadStatusAndProgress: null,
      attachmentsDownloadUrl: []
    };
  },
  watch: {
    part: {
      handler: function(newPart, oldPart) {
        this.init();
      },
      deep: true,
      immediate: true
    },
    brand: {
      handler: function(newPart, oldPart) {
        this.init();
      },
      deep: true,
      immediate: true
    },
    suppliers: {
      handler: function(newPart, oldPart) {
        this.init();
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    init() {
      if(this.part?._id) {
        const attachmentsByOwner = {};
        this.part.attachments.forEach(attachment => {
          if(!Object.keys(attachmentsByOwner).includes(attachment.owner)) {
            attachmentsByOwner[attachment.owner] = [];
          }
          attachmentsByOwner[attachment.owner].push(attachment);
        });
        this.attachmentsByOwner = attachmentsByOwner;
      }
      if(this.brand?._id) {
        this.brandObject = Object.assign({}, this.brand);
      }
      if(this.suppliers) {
        this.suppliersList = JSON.parse(JSON.stringify(this.suppliers));
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    addAttachment(attachment) {
      /**
       * Create Attachment
       */
      const attachmentBody = new this.$BcmModel.Attachment();
      attachmentBody.name = attachment.name;
      attachmentBody.description = attachment.description;
      attachmentBody.public = attachment.public;

      this.$apiInstance.addBrandPartAttachment(this.$route.params.brandUUID, this.$route.params.partUUID, attachmentBody)
        .then((part) => {
          const newAttachment = part.attachments.find(item => item.name === attachmentBody.name);
          if(newAttachment && newAttachment._id) {
            this.$refs.UploadManagerRef.uploadPartAttachment(
              attachment.file,
              this.$route.params.brandUUID,
              this.$route.params.partUUID,
              newAttachment._id
            );
          }else{
            /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
            this.uploadStatusAndProgress = {  
              xhr: null,
              success: false,
              error: true,
              errorMessage: 'Unable to add this attachment',
              progress: false,
              progressStatus: null,
              file: null,
              brandUUID: this.$route.params.brandUUID,
              partUUID: this.$route.params.partUUID,
              attachmentUUID: null,
            };
          }
        }, (error) => {
          /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
          this.uploadStatusAndProgress = {  
            xhr: null,
            success: false,
            error: true,
            errorMessage: ApiErrorParser.parse(error),
            progress: false,
            progressStatus: null,
            file: null,
            brandUUID: this.$route.params.brandUUID,
            partUUID: this.$route.params.partUUID,
            attachmentUUID: null,
          };
        });
    },
    updateAttachment(attachment) {
      /**
       * Update Attachment
       */
      const attachmentBody = new this.$BcmModel.Attachment();
      attachmentBody.name = attachment.name;
      attachmentBody.description = attachment.description;
      attachmentBody.public = attachment.public;

      this.$apiInstance.modifyAdminPartAttachment(this.$route.params.partUUID, attachment._id, attachmentBody)
        .then((part) => {
          /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
          this.uploadStatusAndProgress = {  
            xhr: null,
            success: true,
            error: false,
            errorMessage: null,
            progress: false,
            progressStatus: null,
            file: null,
            brandUUID: null,
            partUUID: this.$route.params.partUUID,
            attachmentUUID: attachment._id,
          };
        }, (error) => {
          /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
          this.uploadStatusAndProgress = {  
            xhr: null,
            success: false,
            error: true,
            errorMessage: ApiErrorParser.parse(error),
            progress: false,
            progressStatus: null,
            file: null,
            brandUUID: this.$route.params.supplierUUID,
            partUUID: this.$route.params.partUUID,
            attachmentUUID: null,
          };
        });
    },
    downloadAttachment(attachment) {
      const [promise, req] = DownloadAdminPartAttachmentFile.downloadAdminPartAttachmentFile(
        this.$apiInstance,
        this.$route.params.partUUID,
        attachment._id,
        attachment.file.extension
      );
      this.$downloadProgress.addDownloadV2(req, attachment.name, attachment.file.extension, promise);
    },
    deleteAttachment(attachment) {
      this.$apiInstance.deleteAdminPartAttachment(this.$route.params.partUUID, attachment._id)
        .then((data) => {
        }, (error) => {
          throw new Error('Error deleting attachment');
        });
    },
    uploadProgress(object) {
      /**
       * {  
       *    xhr: xhr,
       *    success: false,
       *    error: true,
       *    errorMessage: null,
       *    progress: false,
       *    progressStatus: null,
       *    file: file,
       *    brandUUID,
       *    partUUID,
       *    attachmentUUID
       * }
       */
      this.uploadStatusAndProgress = Object.assign({}, object);
    },
    uploadSuccess(object) {
      /**
       * {  
       *    xhr: xhr,
       *    success: false,
       *    error: true,
       *    errorMessage: null,
       *    progress: false,
       *    progressStatus: null,
       *    file: file,
       *    brandUUID,
       *    partUUID,
       *    attachmentUUID
       * }
       */
      this.uploadStatusAndProgress = Object.assign({}, object);;
    },
    uploadError(object) {
      /**
       * {  
       *    xhr: xhr,
       *    success: false,
       *    error: true,
       *    errorMessage: null,
       *    progress: false,
       *    progressStatus: null,
       *    file: file,
       *    brandUUID,
       *    partUUID,
       *    attachmentUUID
       * }
       */
      this.uploadStatusAndProgress = Object.assign({}, object);;
    }
  },
};
</script>
