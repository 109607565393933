<template lang="html" src="./paginatedDataTableParts.template.vue"></template>

<style lang="scss" src="./paginatedDataTableParts.scss"></style>

<script>
const i18nData = require('./paginatedDataTableParts.i18n');

import { SpinnerBeelse } from '@cloudmanufacturingtechnologies/portal-components';

/**
 * Display parts in a data table
 * Works with pagination
 */
export default {
  name: 'PaginatedDataTableParts',
  components: { SpinnerBeelse },
  props: {
    /**
     * The parts data object containing an array of parts and the total number of parts for the selected filters
     */
    partsData: {
      type: Object,
      default: null,
    },
    /**
     * Number of items per page
     */
    pageSize: {
      type: Number,
      required: true
    },
    /**
     * Page number
     */
    pageNumber: {
      type: Number,
      required: true
    },
    /**
     * Sort params
     */
    sortBy: {
      type: String,
      required: true
    },
    sortOrder: {
      type: Number,
      required: true
    },
    /**
     * Loading props
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the image preview should be enabled or not
     */
    enableImagePreview: {
      type: Boolean,
      default: true
    },
    /**
     * Parts image, keys are parts UUID, values are DataURL
     */
    partsImageDataURL: {
      type: Object,
      default: () => {return {};}
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  watch: {
    partsData: {
      handler: function(newVal, odVal) {
        this.initImageTooltipValues();
      }
    },
    pageSize: {
      handler: function(newVal, odVal) {
        this.localPageSize = newVal;
      }
    },
    pageNumber: {
      handler: function(newVal, oldVal) {
        this.localPageNumber = newVal;
      }
    },
    sortBy: {
      handler: function(newVal, oldVal) {
        this.localSortBy = newVal;
        this.localSortDesc = (newVal === -1);
      }
    },
    sortOrder: {
      handler: function(newVal, oldVal) {
        this.localSortOrder = newVal;
      }
    }
  },
  data() {
    return {
      headers: [],
      localPageSize: 15,
      itemsPageSize: [5, 15, 30, 50],
      localPageNumber: 0,
      localSortBy: 'last_modified',
      localSortOrder: -1,
      localSortDesc: true,
      imageTooltipValues: {},
      lastSortUpdate: 0
    };
  },
  created() {
    this.initHeaders();
    this.initImageTooltipValues();
  },
  mounted() {
  },
  computed: {
    currentMinItemIndex() {
      return !this.partsData ? 0 : this.pageNumber * this.pageSize + 1;
    },
    currentMaxItemIndex() {
      return !this.partsData ? 0 : Math.min((this.pageNumber + 1) * this.pageSize, this.partsData.count);
    }
  },
  methods: {
    noSortFn() {
      /**
       * This function allows to prevent the data-table component sorting the rows itself
       */
      return this.partsData?.parts || [];
    },
    initHeaders() {
      this.headers = [
        {
          text: this.$t('Status'),
          value: 'status',
          align: 'center'
        },
        {
          text: this.$t('Name'),
          value: 'name',
        },
        {
          text: this.$t('Brand'),
          value: 'brand_id.name',
          sortable: false
        },
        {
          text: this.$t('Owner'),
          value: 'owner',
          sortable: false
        },
        {
          text: this.$t('Technology'),
          value: 'dna.technology',
        },
        {
          text: this.$t('Material'),
          value: 'dna.material',
        },
        {
          text: this.$t('Supplier'),
          value: 'homologation.supplier.name',
          sortable: false
        },
        {
          text: this.$t('Created'),
          value: 'created',
        },
        {
          text: this.$t('LastModified'),
          value: 'last_modified',
        },
        {
          text: this.$t('Actions'),
          value: 'action',
          align: 'center',
          sortable: false
        }
      ];
    },
    initImageTooltipValues() {
      /**
       * Init the object for each programmatic tooltip
       */
      if(!this.partsData?.parts) {
        return;
      }
      
      for(const part of this.partsData.parts) {
        if(!this.imageTooltipValues.hasOwnProperty(part._id)) {
          this.$set(this.imageTooltipValues, part._id, false);
        }
      }
    },
    rowMouseenter(part) {
      this.imageTooltipValues[part._id] = true;
    },
    rowMouseleave(part) {
      this.imageTooltipValues[part._id] = false;
    },
    rowMouseover(part) {
      if(!this.partsImageDataURL[part._id]) {
        setTimeout(() => {
          if(this.$refs[`row_${part._id}`]?.matches(':hover')) {
            this.$emit('getPartImage', part);
          }
        }, 200);
      }
    },
    goToPageItem(item) {
      this.$emit('goToPageItem', item);
    },
    pageSizeChanged() {
      this.$emit('pageSizeChanged', this.localPageSize);
    },
    loadFirstPage() {
      this.$emit('loadFirstPage');
    },
    loadPreviousPage() {
      this.$emit('loadPreviousPage');
    },
    loadNextPage() {
      this.$emit('loadNextPage');
    },
    loadLastPage() {
      this.$emit('loadLastPage');
    },
    updateSortBy(column) {
      this.localSortBy = column;
      setTimeout(() => {
        this.updateSort();
      }, 25);
    },
    updateSortOrder(desc) {
      this.localSortOrder = desc ? -1 : 1;
      setTimeout(() => {
        this.updateSort();
      }, 25);
    },
    updateSort() {
      if(Date.now() - this.lastSortUpdate > 50) {
        this.lastSortUpdate = Date.now();
        this.$emit('updateSort', this.localSortBy, this.localSortOrder);
      }
    }
  },
};
</script>