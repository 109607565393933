var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-parts"
  }, [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "text-h4"
  }, [_vm._v(" " + _vm._s(_vm.$t('Parts')) + " ")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('AutocompleteFilters', {
    attrs: {
      "filters": _vm.filters
    },
    on: {
      "input": _vm.filtersChanged
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "1",
      "align-self": "center"
    }
  }, [_c('span', {
    staticClass: "text-h6 info--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('Presets')) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "11",
      "align-self": "center"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.selectHomologatedParts
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-check-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('HomologatedParts')) + " ")])], 1), _c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.selectPartsAddedInTheLastWeek
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-calendar-week ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('AddedInTheLastWeek')) + " ")])], 1), _c('v-btn', {
    staticClass: "text-none mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.selectPartsAddedInTheLastMonth
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-calendar-alt ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('AddedInTheLastMonth')) + " ")])], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.selectPartsAddedThisYear
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-calendar-check ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('AddedThisYear')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', [_c('v-card-text', [_c('PaginatedDataTableParts', {
    attrs: {
      "parts-data": _vm.partsData,
      "page-size": _vm.pageSize,
      "page-number": _vm.pageNumber,
      "sort-by": _vm.sortBy,
      "sort-order": _vm.sortOrder,
      "loading": _vm.loading,
      "parts-image-data-u-r-l": _vm.partsImageDataURL
    },
    on: {
      "getPartImage": _vm.getBrandPartImage,
      "pageSizeChanged": _vm.pageSizeChanged,
      "loadPreviousPage": _vm.loadPreviousPage,
      "loadNextPage": _vm.loadNextPage,
      "loadFirstPage": _vm.loadFirstPage,
      "loadLastPage": _vm.loadLastPage,
      "updateSort": _vm.updateSort,
      "goToPageItem": _vm.goToPageItem
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }