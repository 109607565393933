import { render, staticRenderFns } from "./pagePart.template.vue?vue&type=template&id=8db62f66&lang=html&"
import script from "./PagePart.vue?vue&type=script&lang=js&"
export * from "./PagePart.vue?vue&type=script&lang=js&"
import style0 from "./pagePart.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports